import { RegisterAcademicPageModule } from './reister-academic/register-academic-page.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorPageComponent } from './error-page/error-page.component';

const routes: Routes = [
  // { path: "start", loadChildren: './reister-academic/register-academic-page.module#RegisterAcademicPageModule' },
  { path: "", loadChildren: './reister-academic/register-academic-page.module#RegisterAcademicPageModule' },
  { path: "university", loadChildren: './university/university-page.module#UniversityPageModule' },
  { path: "reviewSubmit", loadChildren: 'src/app/review-submit/review-submit.module#ReviewSubmitModule' },
  { path: "preview", loadChildren: './transcript-preview/transcript-preview.module#TranscriptPreviewModule' },
  { path: "uploadManual", loadChildren: 'src/app/manual-upload/manual-upload.module#ManualUploadModule' },
  { path: "thankyou", loadChildren: 'src/app/confirmation/confirmation.module#ConfirmationModule' },
  { path: "freshman", loadChildren: 'src/app/confirmation/confirmation.module#ConfirmationModule' },
  { path: "termsOfUse", loadChildren: './reusableComponents/terms-condition/terms-condition.module#TermsConditionModule' },
  { path: "privacyPolicy", loadChildren: './reusableComponents/privacy-policy/privacy-policy.module#PrivacyPolicyModule' },
  { path: "**", component: ErrorPageComponent  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
