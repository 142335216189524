import { Component, OnInit } from '@angular/core';
import { RegisterAcademicPageService } from "src/app/reister-academic/register-academic-page.service";
import { AppService } from "src/app/app.service";
import * as $ from 'jquery';
import { Router } from "@angular/router";

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  portalHostname: string;
  hostName: string;
  portalDynamictext: any;
  bgColor: any;
  loadingFinished = false;
  constructor(private registerAcademicPageService: RegisterAcademicPageService, private appService: AppService,private router: Router) {
    this.portalDynamictext = JSON.parse(sessionStorage.getItem("portalDynamicText"));
  }

  ngOnInit() {
    if (null != this.portalDynamictext) {
      this.bgColor = this.portalDynamictext.dynamicElements.buttons.primary.normalColor;
      this.setColors();
    } else {
      this.hostName = window.location.hostname;
      if (this.hostName == 'localhost' || this.hostName == '10.0.5.57' || this.hostName == '10.0.5.114') {
          this.portalHostname = 'portal-dev.measureone.com';
      }
      else {
          this.portalHostname = this.hostName;
      }
      this.registerAcademicPageService.getPortalDetails(this.portalHostname).subscribe(portalDetails => {
          sessionStorage.setItem('token', portalDetails['token'])
          this.portalDynamictext = JSON.parse(portalDetails['portalDynamictext']);
          this.setColors();
      });
    }
  }

  setColors() {
    this.bgColor = this.portalDynamictext['dynamicElements']['buttons']['primary']['normalColor'];
    for (var i = 0; i < document.getElementsByClassName('cls-1').length; i++) {
      if (document.getElementsByClassName('cls-1')[i]) {
        document.getElementsByClassName('cls-1')[i].setAttribute('style', 'fill: ' + this.bgColor);
      }
    }
    this.loadingFinished = true;    
    this.appService.stopLoader();
  }
  goToHome() {
    this.router.navigate(['/']);
  }
}
