import { AppService } from './../app.service';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'bearer ' + sessionStorage.getItem('token')
  })
};
@Injectable({
  providedIn: 'root',
})
export class RegisterAcademicPageService {

  constructor(private http: HttpClient, private appService: AppService) { }

  getPortalDetails(portalHostName: string) {
    this.appService.startLoader();
    let url = '/m120Services/guests/portalDetails';
    let reqDataJSON = {
      "portalHost": portalHostName
    }
    // return this.http.post(url, reqDataJSON);
    return this.appService.unsecureClientService(url, "post", reqDataJSON);


  }

  getDecryptedInfo(info) {
    this.appService.startLoader();
    let url = '/m120Services/secure/lender/portal_params/decryption';
    let reqDataJSON = info
    // return this.http.post(url, reqDataJSON, httpOptions);
    return this.appService.secureClientService(url, "POST", reqDataJSON)
  }

  getAcademicLevel() {
    let url = '/m120Services/secure/common/academiclevel';
    return this.appService.secureClientService(url, "GET", null)
  }

  validateApplication(formValues) {
    let url = "/m120Services/secure/students/validateApplication"
    let clientGivenDetails = JSON.parse(sessionStorage.getItem('clientGivenDetails'))
    let reqDataJSON = {
      "firstName": formValues.firstName,
      "lastName": formValues.lastName,
      "email": formValues.email,
      "applicationId": ((sessionStorage.getItem("applicationId") && sessionStorage.getItem("applicationId") != null && sessionStorage.getItem("applicationId") != "") && sessionStorage.getItem("applicationId") != "undefined") ? sessionStorage.getItem("applicationId") : (formValues.applicationId != null && formValues.applicationId != undefined && formValues.applicationId != '') ? formValues.applicationId : null,
      "lenderId": sessionStorage.getItem("lenderId"),
      "lenderApplicationId": 2,
      "isAuthorized": 1,
      "unitId": clientGivenDetails != null ? clientGivenDetails.unitId : null,
      "academicLevel": formValues.academicLevel != null ? formValues.academicLevel : null,
      "degreeLevel": clientGivenDetails != null ? clientGivenDetails.degreeLevel : null
    }
    // return this.http.post(url, reqDataJSON, httpOptions);
    return this.appService.secureClientService(url, "post", reqDataJSON);
  }

  getFavicon(portalHostName) {
    let url = '/m120Services/guests/download?portalHostName=' + portalHostName + '&imageType=FAVICON';

    return this.http.get(url, { responseType: 'text' });

  }

  getSchoolList() {
    let url = '/m120Services/secure/api/v1/schools/find';
    let reqDataJSON = JSON.stringify({
      "searchText": "*:*",
      "sortBy": ["schoolName"],
      "filterQuery": { "schoolTypeName": ["US School"] },
      "sortOrder": ["ASC"],
      "totalInList": 100000,
      "total": 100000
    });
    return this.appService.secureClientService(url, 'post', reqDataJSON);
  }

  studentEnrollment(json) {
    let url = '/m120Services/secure/studentenrollments/update/';
    let reqDataJSON = JSON.stringify(json);
    return this.appService.secureClientService(url, 'put', reqDataJSON);
  }

  getSchoolDegrees() {
    let url = '/m120Services/secure/common/degreetypes/';
    return this.appService.secureClientService(url, 'get', '');
  }

  getEnrollments(id) {
    let url = '/m120Services/secure/transactions/' + id + '/enrollments';
    return this.appService.secureClientService(url, 'get', '');
  }

  getLenderPreferences(token, url) {
    return this.appService.secureClientService(url, 'get', '');
  }
} 