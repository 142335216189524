import { Component } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private appService: AppService) {
    this.appService.init('dec9ddd172e8b4ed9f406fe43028b56e');
  }
  title = 'trans-portal-test';
}
