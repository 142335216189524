import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from "@angular/common/http";
import * as mixpanel from 'mixpanel-browser';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + sessionStorage.getItem('token')
    })
};

const unsecureHttpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({
    providedIn: 'root'
})
export class AppService {

    constructor(private http: HttpClient) {

    }
    secureClientService(url, method, reqData) {
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + sessionStorage.getItem('token')
            })
        };
        method = method.toUpperCase();
        switch (method) {
            case "POST": {
                return this.http.post(url, reqData, httpOptions)

            }
            case "GET": {

                return this.http.get(url, httpOptions)

            }
            case "PUT": {

                return this.http.put(url, reqData, httpOptions)

            }
            case "DELETE": {

                return this.http.delete(url, httpOptions)

            }

        }

    }
    secureClientServiceFile(url, method, reqData) {
        let httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'bearer ' + sessionStorage.getItem('token')
            })
        };
        method = method.toUpperCase();
        switch (method) {
            case "POST": {
                return this.http.post(url, reqData, httpOptions)

            }
            case "GET": {

                return this.http.get(url, httpOptions)

            }
            case "PUT": {

                return this.http.put(url, reqData, httpOptions)

            }
            case "DELETE": {

                return this.http.delete(url, httpOptions)

            }

        }

    }
    unsecureClientService(url, method, reqData) {
        method = method.toUpperCase();
        switch (method) {
            case "POST": {
                return this.http.post(url, reqData, unsecureHttpOptions)

            }
            case "GET": {
                return this.http.get(url)

            }
            case "PUT": {
                return this.http.put(url, reqData, unsecureHttpOptions)

            }
            case "DELETE": {
                return this.http.delete(url)

            }

        }
    }

    logActivity(description, funnelEvent, statusId) {
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + sessionStorage.getItem('token')
            })
        };
        let url = "/m120Services/secure/transactions/logactivity";
        let requestData = {
            "description": description,
            "funnelEvent": funnelEvent,
            "mapTo": "TRANSACTIONACTIVITY",
            "transactionActivityStatusId": statusId,
            "transactionId": sessionStorage.getItem("transactionId")
        }
        return this.http.put(url, requestData, httpOptions).subscribe(res => { });
    }

    startLoader() {
        document.getElementById("loading").style.display = "block";
    }
    stopLoader() {
        document.getElementById("loading").style.display = "none";
    }

    startPlainLoader() {
        document.getElementById("loadingPlain").style.display = "block";
    }
    stopPlainLoader() {
        document.getElementById("loadingPlain").style.display = "none";
    }
    /**
   * Initialize mixpanel.
   *
   * @param {string} userToken
   * @memberof MixpanelService
   */
  init(userToken: string): void {
    mixpanel.init(userToken);
  }
  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }
}